import validateEmail from 'validator/es/lib/isEmail';

export function toString(value: any) {
    return !!value ? value.toString() : '';
}

export function isRequired(value: any): string | null {
    return toString(value).trim().length > 0 ? null : 'required';
}

export function isEmail(value: any): string | null {
    return validateEmail(toString(value).trim()) ? null : 'email';
}

export function isInteger(value: any): string | null {
    return Number.isInteger(Number(value)) ? null : 'integer';
}

const PHONENUMBER_PATTERN = /^\+?[0-9]{1,3}[0-9_\- \(\)]+$/;
export function isPhoneNumber(value: any) {
    const numberAsString = toString(value);

    const length = numberAsString.replace(/[^0-9]/g, '').length;
    const correctLength = length >= 4 && length <= 15;
    const correctPattern = PHONENUMBER_PATTERN.test(numberAsString);

    return correctLength && correctPattern ? null : 'phone';
}
