import classNames from 'classnames';
import React from 'react';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import * as styles from './BaseLayout.module.scss';

export type BaseLayoutProps = {
    children: React.ReactNode;
    dynamicNavigationBackground?: boolean;
    navigationPosition?: 'sticky' | 'fixed';
    footerSpacing?: 'default' | 'none';
};

function BaseLayout({
    children,
    navigationPosition = 'sticky',
    dynamicNavigationBackground = false,
    footerSpacing = 'default'
}: BaseLayoutProps) {
    return (
        <div
            className={classNames(styles.root, {
                [styles.navigationSticky]: navigationPosition === 'sticky',
                [styles.footerSpacingNone]: footerSpacing === 'none'
            })}
        >
            <Navigation darkBackground={!dynamicNavigationBackground} />
            <div className={styles.main}>{children}</div>
            <Footer />
        </div>
    );
}

export default BaseLayout;
