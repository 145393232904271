import * as posthog from '../analytics/posthog';
import { BaseHead } from '../components/BaseHead';
import ContactForm from '../containers/ContactForm';
import BaseLayout from '../layouts/BaseLayout';

const trackSubmitContactForm = posthog.createTrackEvent('contact-page:contact-form-submit');

export type ContactPageProps = {};

export function ContactPage({}: ContactPageProps) {
    return (
        <BaseLayout>
            <div className="container--xs">
                <h1>Ihr digitaler Kaffee-Termin</h1>
                <p>Lassen Sie gerne Ihre Kontaktdaten da. Wir melden uns bei Ihnen und freuen uns auf den Austausch.</p>
                <ContactForm onSubmit={trackSubmitContactForm} />
            </div>
        </BaseLayout>
    );
}

export const Head = BaseHead;

export default ContactPage;
