import * as Sentry from '@sentry/gatsby';
import { Button, FormActions, FormField, Input, Select } from '@truffls/design-system-react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { useRef } from 'react';
import { Field, Form } from 'react-final-form';

import { FairConfigFragment } from '../../fragments/FairConfigFragment';
import { getStartRoute } from '../../routes';
import { getError } from '../../utils/form/error';
import { isRequired } from '../../utils/form/validators';

import {
    getErrorMessage,
    validateEmail,
    validateEmployees,
    validatePhone,
    validatePlannedEmployments,
    validateSalutation
} from './ConfactForm.utils';

import * as styles from './ContactForm.module.scss';

type StaticQueryData = FairConfigFragment;

export type ContactFormProps = {
    onSubmit?: () => void;
};

function ContactForm({ onSubmit }: ContactFormProps) {
    const { fairConfig } = useStaticQuery<StaticQueryData>(graphql`
        {
            ...FairConfigFragment
        }
    `);

    const formElementRef = useRef<HTMLFormElement>(null);

    const ownOnSubmit = (values: object) => {
        onSubmit?.();

        if (!formElementRef.current) {
            Sentry.captureException(new Error('Form element reference is empty'));
            return;
        }

        const formElement = formElementRef.current;
        const formAction = formElement.getAttribute('data-action');
        const formMethod = formElement.getAttribute('data-method');

        if (!formAction || !formMethod) {
            Sentry.captureException(new Error('Form element does not have data-action or data-method attribute'));
            return;
        }

        if (!('accept_data_privacy' in values) || !values.accept_data_privacy) {
            return;
        }

        formElement.action = formAction;
        formElement.method = formMethod;
        formElement.submit();
    };

    return (
        <Form onSubmit={ownOnSubmit}>
            {({ handleSubmit }) => {
                return (
                    <form
                        data-action={
                            new URL(
                                '/servlet/servlet.WebToLead?encoding=UTF-8',
                                process.env.GATSBY_SALESFORCE_WEB_TO_LEAD_BASE_URL
                            ).href
                        }
                        data-method="POST"
                        onSubmit={handleSubmit}
                        ref={formElementRef}
                    >
                        <input
                            type="hidden"
                            name="oid"
                            value={process.env.GATSBY_SALESFORCE_WEB_TO_LEAD_ORGANIZATION_ID}
                        />
                        <input
                            type="hidden"
                            name="retURL"
                            value={new URL(getStartRoute({ contactSuccess: true }), process.env.GATSBY_APP_URL).href}
                        />

                        <input type="hidden" name="lead_source" value={fairConfig.config.salesforceLeadSource} />
                        {/* Absolventa-Lead: true */}
                        <input type="hidden" name="00N0600000KAGtk" value="1" />
                        <input
                            type="hidden"
                            name="Campaign_ID"
                            value={process.env.GATSBY_SALESFORCE_WEB_TO_LEAD_CAMPAIGN_ID}
                        />

                        <Field name="salutation" validate={validateSalutation} defaultValue="-">
                            {({ input, meta }) => (
                                <FormField
                                    id="salutation"
                                    labelText="Anrede *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Select {...input}>
                                        <option hidden value="__null__"></option>
                                        <option value="Herr">Herr</option>
                                        <option value="Frau">Frau</option>
                                        <option value="">Keine Angabe</option>
                                    </Select>
                                </FormField>
                            )}
                        </Field>

                        <div className={styles.formRow}>
                            <Field type="text" name="first_name" validate={isRequired}>
                                {({ input, meta }) => (
                                    <FormField
                                        id="first_name"
                                        labelText="Vorname *"
                                        hasError={!!getError(meta)}
                                        helpText={getErrorMessage(meta)}
                                    >
                                        <Input {...input} autoComplete="given-name" maxLength={40} />
                                    </FormField>
                                )}
                            </Field>
                            <Field type="text" name="last_name" validate={isRequired}>
                                {({ input, meta }) => (
                                    <FormField
                                        id="last_name"
                                        labelText="Nachname *"
                                        hasError={!!getError(meta)}
                                        helpText={getErrorMessage(meta)}
                                    >
                                        <Input {...input} autoComplete="family-name" maxLength={80} />
                                    </FormField>
                                )}
                            </Field>
                        </div>
                        <Field type="email" name="email" validate={validateEmail}>
                            {({ input, meta }) => (
                                <FormField
                                    id="email"
                                    labelText="E-Mail *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} autoComplete="email" maxLength={80} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="text" name="phone" validate={validatePhone}>
                            {({ input, meta }) => (
                                <FormField
                                    id="phone"
                                    labelText="Telefon *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} autoComplete="tel" maxLength={40} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="text" name="company" validate={isRequired}>
                            {({ input, meta }) => (
                                <FormField
                                    id="company"
                                    labelText="Unternehmen *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} autoComplete="organization" maxLength={40} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="text" name="zip" validate={isRequired}>
                            {({ input, meta }) => (
                                <FormField
                                    id="postalcode"
                                    labelText="Postleitzahl *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} autoComplete="postal-code" maxLength={40} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="number" name="employees" validate={validateEmployees}>
                            {({ input, meta }) => (
                                <FormField
                                    id="employees"
                                    labelText="Anzahl an Mitarbeitenden"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} min={0} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="number" name="00N0600000KAGtn" validate={validatePlannedEmployments}>
                            {({ input, meta }) => (
                                <FormField
                                    id="plannedemployments"
                                    labelText="Anzahl geplanter Einstellungen *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} min={0} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="text" name="00N2400000J57jq" validate={isRequired}>
                            {({ input, meta }) => (
                                <FormField
                                    id="message"
                                    labelText="Ihre Anfrage *"
                                    hasError={!!getError(meta)}
                                    helpText={getErrorMessage(meta)}
                                >
                                    <Input {...input} multiline minRows={5} />
                                </FormField>
                            )}
                        </Field>
                        <Field type="checkbox" name="accept_data_privacy" validate={isRequired}>
                            {({ input, meta }) => (
                                <div
                                    className={classNames(styles.checkboxRow, {
                                        'tf-has-error': !!getError(meta)
                                    })}
                                >
                                    <span className="tf-checkbox">
                                        <input {...input} id="accept_data_privacy" />
                                        <label
                                            htmlFor="accept_data_privacy"
                                            className="tf-checkbox__label"
                                            aria-hidden="true"
                                        >
                                            <span className="tf-checkbox__faux"></span>
                                        </label>
                                    </span>
                                    <label htmlFor="accept_data_privacy" className="tf-checkbox__label-text">
                                        Einwilligung zur Kontaktaufnahme durch die FUNKE Works GmbH. Die Einwilligung
                                        kann jederzeit widerrufen werden. Ihre Daten werden im gesetzlich zulässigen
                                        Rahmen gemäß Art.6 Abs.1 a, b, f DSGVO verarbeitet. Weitere Informationen finden
                                        Sie in unserer{' '}
                                        <a href="https://truffls.de/de/datenschutz/" target="_blank">
                                            Datenschutzerklärung.
                                        </a>
                                    </label>
                                </div>
                            )}
                        </Field>

                        <FormActions className={styles.formActions}>
                            <Button typeStyle="raised" variationStyle="brand" className={styles.button}>
                                Absenden
                            </Button>
                        </FormActions>
                    </form>
                );
            }}
        </Form>
    );
}

export default ContactForm;
