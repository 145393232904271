import { FieldValidator } from 'final-form';
import { FieldMetaState } from 'react-final-form';

import { getError } from '../../utils/form/error';
import { isEmail, isInteger, isPhoneNumber, isRequired, toString } from '../../utils/form/validators';

export const validateSalutation: FieldValidator<string> = (value) => {
    const salutation = toString(value).trim();
    let salutationToValidate = salutation;

    // We can't use '__null__' as the inital empty value because Salesforce expected '' to be the value for "No information".
    // So we need to convert '__null__' to '' and '' to 'n/a' and validate it afterwards.
    if (salutation === '__null__') {
        salutationToValidate = '';
    } else if (salutation === '') {
        salutationToValidate = 'n/a';
    }

    return isRequired(salutationToValidate);
};

export const validateEmail: FieldValidator<string> = (value) => {
    return isRequired(value) || isEmail(value);
};
export const validatePhone: FieldValidator<string> = (value) => {
    return isRequired(value) || isPhoneNumber(value);
};

export const validateEmployees: FieldValidator<string> = (value) => {
    if (toString(value).trim().length === 0) {
        return null;
    }

    return isInteger(value);
};

export const validatePlannedEmployments: FieldValidator<string> = (value) => {
    return isRequired(value) || isInteger(value);
};

export function getErrorMessage(meta: FieldMetaState<any>): string | null {
    const error = getError(meta);

    if (!error) {
        return null;
    }

    if (error === 'required') {
        return 'Pflichtfeld';
    } else if (error === 'email') {
        return 'Ungültige E-Mail Adresse';
    } else if (error === 'phone') {
        return 'Ungültige Telefonnummer';
    } else if (error === 'integer') {
        return 'Ungültige Zahl';
    }

    return 'Ungültige Eingabe';
}
